
import React from 'react'
import Layout from '../components/layout'
import { 
  Container,
  Row,
  Col
} from 'react-bootstrap'


const Pricing = (props) => {
  return (
    <>
      <Layout>
        <section className='white-color black-text'>
          <Container>
            <Row><Col className='mt-5 pt-5'><h1 className='highlight-text'>Pricing</h1></Col></Row>
            <Row>
              <Col className='my-5'>
                <table className="table table-striped white-color">
                  <thead>
                    <tr className='dark-color white-text'>
                      <th scope="col">Item</th>
                      <th scope="col">Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Price Per Month Per User</th>
                      <td>£5/ €6</td>
                    </tr>
                    <tr>
                      <th scope="row">Minimum Fee Per Company per Month</th>
                      <td>£25/ €30</td>
                    </tr>
                    <tr>
                      <th scope="row">Unlimited Projects</th>
                      <td><span className='material-icons'>done</span></td>
                    </tr>
                    <tr>
                      <th scope="row">Unlimited Timesheets</th>
                      <td><span className='material-icons'>done</span></td>
                    </tr>
                    <tr>
                      <th scope="row">Unlimited Expense Claims</th>
                      <td><span className='material-icons'>done</span></td>
                    </tr>
                    <tr>
                      <th scope="row">Automated Billing	</th>
                      <td><span className='material-icons'>done</span></td>
                    </tr>
                    <tr>
                      <th scope="row">All-inclusive Price</th>
                      <td><span className='material-icons'>done</span></td>
                    </tr>
                    <tr>
                      <th scope="row">Email/ Phone Support</th>
                      <td><span className='material-icons'>done</span></td>
                    </tr>
                    <tr>
                      <th scope="row">Secure Rackspace Hosting</th>
                      <td><span className='material-icons'>done</span></td>
                    </tr>
                    <tr>
                      <th scope="row">30 Day Notice	</th>
                      <td><span className='material-icons'>done</span></td>
                    </tr>
                    <tr>
                      <th scope="row">Free Training System	</th>
                      <td><span className='material-icons'>done</span></td>
                    </tr>
                    <tr>
                      <th scope="row">Document Storage</th>
                      <td><span className='material-icons'>done</span></td>
                    </tr>
                    <tr>
                      <th scope="row">Project Budgets		</th>
                      <td><span className='material-icons'>done</span></td>
                    </tr>
                    <tr>
                      <th scope="row">Accounts Link	</th>
                      <td><span className='material-icons'>done</span></td>
                    </tr>
                    <tr>
                      <th scope="row">CRM and Lead Management</th>
                      <td><span className='material-icons'>done</span></td>
                    </tr>
                  </tbody>
                </table>`
              </Col>
            </Row>
          </Container>
        </section>
        
      </Layout>
    </>
  )
}

export default Pricing